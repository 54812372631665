<template>

  <section class="forms">
    <div class="page-header">
      <h3 class="page-title">
        Add New Style
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Styles</a></li>
          <li class="breadcrumb-item active" aria-current="page">New</li>
        </ol>
      </nav>
    </div>
    <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form">
    <form @submit.prevent="handleSubmit(onSubmit)" class="forms-sample">
    <div class="row">
      <div class="col-md-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <ValidationProvider name="name" rules="required">
              <b-form-group slot-scope="{ errors }" label="Name"  label-for="name">
                <b-form-input type="text" id="name" v-model="name" placeholder="Name"></b-form-input>
                <p>{{ errors[0] }}</p>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="city" rules="required">
              <b-form-group slot-scope="{ errors }" label-for="city" horizontal label="City">
                <b-form-select id="city" v-model="city_id" :options="cities"/>
                <p>{{ errors[0] }}</p>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="commercial_fill" rules="">
              <b-form-group slot-scope="{ errors }" label-for="color" horizontal label="Commercial Fill Color">
                    <ColorPicker
                     :color="commercial_color"
                     :onChange="color => updateCommercialFill(color)"
                     />
                <p>{{ errors[0] }}</p>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="residential_fill" rules="">
              <b-form-group slot-scope="{ errors }" label-for="color" horizontal label="Residential Fill Color">
                    <ColorPicker
                     :color="residential_color"
                     :onChange="color => updateResidentialFill(color)"
                     />
                <p>{{ errors[0] }}</p>
              </b-form-group>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <div class="col-md-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <ValidationProvider name="default_fill" rules="">
              <b-form-group slot-scope="{ errors }" label-for="color" horizontal label="Default Plot Fill Color">
                    <ColorPicker
                     :color="default_color"
                     :onChange="color => updateDefaultFill(color)"
                     />
                <p>{{ errors[0] }}</p>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="commercial_font" rules="">
              <b-form-group slot-scope="{ errors }" label-for="color" horizontal label="Commercial Font Color">
                    <ColorPicker
                     :color="commercial_font_color"
                     :onChange="color => updateCommercialFont(color)"
                     />
                <p>{{ errors[0] }}</p>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="residential_font" rules="">
              <b-form-group slot-scope="{ errors }" label-for="color" horizontal label="Residential Font Color">
                    <ColorPicker
                     :color="residential_font_color"
                     :onChange="color => updateResidentialFont(color)"
                     />
                <p>{{ errors[0] }}</p>
              </b-form-group>
            </ValidationProvider>
          </div>
        </div>
      </div>

      <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <b-form-group class="float-right">
              <b-button type="submit" variant="success" :disabled="invalid" class="mr-2">{{ create_text }}</b-button>
              <b-button type="button" @click="resetForm" variant="light">Reset</b-button>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    </form>
    </ValidationObserver>
    <vue-snotify></vue-snotify>
  </section>

</template>

<script lang="js">
/* eslint-disable no-debugger, no-console */

import { ValidationObserver } from 'vee-validate'
import { ColorPicker } from 'vue-color-gradient-picker'

export default {
  name: 'PlanAddStyle',
  data () {
    return {
      name: "",
      city_id: "",
      create_text: "Add Style",
      body: {
        name: "",
        city_id: "",
        commercial_fill_color: "",
        residential_fill_color: "",
        default_plot_fill_color: "",
        commercial_font_color: "",
        residential_font_color: ""
      },
      commercial_color: {
        red: 255,
        green: 0,
        blue: 0,
        alpha: 1
      },
      residential_color: {
        red: 255,
        green: 0,
        blue: 0,
        alpha: 1
      },
      default_color: {
        red: 255,
        green: 0,
        blue: 0,
        alpha: 1
      },
      commercial_font_color: {
        red: 255,
        green: 0,
        blue: 0,
        alpha: 1
      },
      residential_font_color: {
        red: 255,
        green: 0,
        blue: 0,
        alpha: 1
      }
    }
  },
  components: {
    ValidationObserver,
    ColorPicker
  },
  computed: {
    cities() {
      return this.$store.getters['city/listCities']
    }
  },
  watch: {
    city_id() {
      this.body.city_id = this.city_id
    },
    name() {
      this.body.name = this.name
    }
  },
  methods: {
    onSubmit() {
      this.create_text = "Adding"

      this.$store.dispatch('plan/addStyle', this.body)
      .then(() => {
        this.create_text = "Add Style"
        this.resetForm()
        this.$snotify.success("Style added successfully!", {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
      .catch(() => {
        this.create_text = "Add Style"

        this.$snotify.warning("Something went wrong!", {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      }) 
    },
    updateCommercialFill(color) {
      this.body.commercial_fill_color = color.style
    },
    updateResidentialFill(color) {
      this.body.residential_fill_color = color.style
    },
    updateDefaultFill(color) {
      this.body.default_plot_fill_color = color.style
    },
    updateCommercialFont(color) {
      this.body.commercial_font_color = color.style
    },
    updateResidentialFont(color) {
      this.body.residential_font_color = color.style
    },
    resetForm() {
      this.plot = this.city_id = ""
      this.$nextTick(() => {
        this.$refs.form.reset()
      })
    }
  },
  created() {
    const payload = {
      meta: {
        page: 1,
        paginate: 200
     },
   }
   this.$store.dispatch("city/fetchCities", payload)
  }
}
</script>
<style src="vue-color-gradient-picker/dist/index.css" lang="css" />
